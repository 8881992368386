import './index.module.scss';
import { Component } from 'react';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import InputMask from 'react-input-mask';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  EffectFade,
  Navigation,
  Scrollbar,
  Mousewheel,
  Pagination,
} from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import axios from 'axios';
import { validateForm } from '../../../assets/js/custom-validation';
import logo from '../../../assets/images/logo.png';

const { apiUrl } = process.env;
const { mailTo } = process.env;
const { mailCc } = process.env;
const { marketingWebUrl } = process.env;

/* eslint-disable-next-line */
export interface IndexProps {}

export class Index extends Component<IndexProps> {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      first: '',
      last: '',
      phone: '',
      financialAdviser: '',
      investmentPhilosophy: '',
      investorType: '',
      investingExperience: '',
      investableAssets: '',
      hearAboutUs: '',
      readBlogs: '',
      contactMethod: '',
      message: '',
      emailValid: false,
      firsName: false,
      lastName: false,
      submitDisabled: true,
      mailSent: false,
      error: null,
      isValidated: false,
      slideCount: 0,
    };
  }
  handleChangeEmail = (e) => {
    const emailValid = e.target.value ? true : false;
    const submitValid =
      this.state.firsName && this.state.lastName && emailValid;
    this.setState({
      email: e.target.value,
      emailValid: emailValid,
      submitDisabled: !submitValid,
    });
  };

  handleChangeText = (e) => {
    const firsName = e.target.value ? true : false;
    const lastName = e.target.value ? true : false;
    const submitValid = this.state.emailValid && firsName && lastName;
    this.setState({
      first: e.target.value,
      firsName: firsName,
      lastName: lastName,
      submitDisabled: !submitValid,
    });
  };

  handleChangeLastName = (e) => {
    const lastName = e.target.value ? true : false;
    const submitValid =
      this.state.emailValid && this.state.firsName && lastName;
    this.setState({
      last: e.target.value,
      firsName: lastName,
      submitDisabled: !submitValid,
    });
  };

  handleRequestFormSubmit(event) {
    event.preventDefault();
    // Reset State
    this.setState({ error: null, mailSent: false });
    const { formEl } = this;
    const templateData = `
      <!doctype html>
      <html lang='en'>
      <head>
          <meta charset='UTF-8'><meta name='viewport' content='width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0'>
          <meta http-equiv="X-UA-Compatible" content="ie=edge">
          <title>New Project Enquiry</title>
          <style>
              .container {width: 100%; max-width: 1000px; margin: 0 auto; padding: 0 15px}
              table { width: 100%; }
              .enrollingTemplate th p {margin: 0; text-align: left; font-weight: normal}
              .enrollingTemplate h2 {margin: 50px 0 25px}
              .enrollingTemplate tbody p {margin: 0; line-height: 23px}
              .enrollingTemplate tbody p+p {margin: 15px 0 0;}
              .enrollingTemplate ul {padding-left: 15px}
              .enrollingTemplate ul li {margin: 10px 0 0}
              .enrollingTemplate ul li ul {padding-left: 25px; list-style: disc}
              .enrollingTemplate ul li ul li {margin: 5px 0 0}
              .d-flex {display: flex; margin: 50px 0 25px; width: 100%}
              .double-img img { margin: 15px; max-width: 40%; max-height: 150px }
              p { padding-bottom: 15px; }
              .vcolor{ color: #118440; }
          </style>
      </head>
      <body>
          <div class="container">
              <div class="enrollingTemplate">
                  <p>Dear DGM Team,<br><br>You are receiving this because someone has posted a New Request For Quote Inquiry from the website. <a href="${marketingWebUrl}">${marketingWebUrl}</a></p>
                  <table border=1>
                      <tbody>
                          <tr><td><b>Email*</b></td><td><span class="vcolor">[Email]</span></td></tr>
                          <tr><td><b>First Name*</b></td><td><span class="vcolor">[FirstName]</span></td></tr>
                          <tr><td><b>Last Name*</b></td><td><span class="vcolor">[LastName]</span></td></tr>
                          <tr><td><b>Phone</b></td><td><span class="vcolor">[Phone]</span></td></tr>
                          <tr><td><b>Have you worked with a financial adviser before?</b></td><td><span class="vcolor">[FinancialAdviser]</span></td></tr>
                          <tr><td><b>How familiar are you with our investment philosophy?</b></td><td><span class="vcolor">[InvestmentPhilosophy]</span></td></tr>
                          <tr><td><b>What kind of investor are you?</b></td><td><span class="vcolor">[InvestorType]</span></td></tr>
                          <tr><td><b>What is your investing experience?</b></td><td><span class="vcolor">[InvestingExperience]</span></td></tr>
                          <tr><td><b>Investable Assets</b></td><td><span class="vcolor">[InvestableAssets]</span></td></tr>
                          <tr><td><b>Finally, how'd you hear about us?</b></td><td><span class="vcolor">[HearAboutUs]</span></td></tr>
                          <tr><td><b>How Often Do you Read Our Blogs?</b></td><td><span class="vcolor">[ReadBlogs]</span></td></tr>
                          <tr><td><b>Contact Method</b></td><td><span class="vcolor">[ContactMethod]</span></td></tr>
                          <tr><td colspan='2'><b>Why did you reach out?</b></td></tr>
                          <tr><td colspan='2'><span class="vcolor">[Message]</span></td></tr>
                      </tbody>
                  </table>
                  <p>Regards, DGM Team<br><br><img src="${logo}" width="10%" alt="DGM"/></p>
              </div>
          </div>
      </body>
      </html>`;
    // If form validate
    if (validateForm(formEl)) {
      const html = templateData
        .replace('[FirstName]', this.state.first)
        .replace('[LastName]', this.state.last)
        .replace('[Email]', this.state.email)
        .replace('[Phone]', this.state.phone)
        .replace('[FinancialAdviser]', this.state.financialAdviser)
        .replace('[InvestmentPhilosophy]', this.state.investmentPhilosophy)
        .replace('[InvestorType]', this.state.investorType)
        .replace('[InvestingExperience]', this.state.investingExperience)
        .replace('[InvestableAssets]', this.state.investableAssets)
        .replace('[HearAboutUs]', this.state.hearAboutUs)
        .replace('[ReadBlogs]', this.state.readBlogs)
        .replace('[ContactMethod]', this.state.contactMethod)
        .replace('[Message]', this.state.message);

      const data = {
        emailTo: mailTo,
        emailCc: mailCc,
        emailSubject: 'New Request For Quote Inquiry - DGM Website',
        emailTemplate: html,
      };

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${apiUrl}/mailer/send`,
          headers: { 'content-type': 'application/json' },
          data,
        })
          .then((result) => {
            if (result.data.message == 'Email has been sent successfully.') {
              this.setState({
                first: '',
                last: '',
                email: '',
                phone: '',
                financialAdviser: '',
                investmentPhilosophy: '',
                investorType: '',
                investingExperience: '',
                investableAssets: '',
                hearAboutUs: '',
                readBlogs: '',
                contactMethod: '',
                message: '',
                mailSent: true,
                isValidated: false,
              });
              //window.location.reload();
              window.setTimeout(function () {
                window.location.href = '/who-we-are';
              }, 5000);
            } else {
              this.setState({ error: result.data.error });
            }
          })
          .catch((error) => {
            this.setState({ error: error.message });
          });
      });
    }
    this.setState({ isValidated: true });
    return false;
  }

  render() {
    if (typeof window !== 'undefined') {
      const setActive = (element, active) => {
        const formField = element.parentNode.parentNode;
        if (active) {
          formField.classList.add('form-field--is-active');
        } else {
          formField.classList.remove('form-field--is-active');
          element.value === ''
            ? formField.classList.remove('form-field--is-filled')
            : formField.classList.add('form-field--is-filled');
        }
      };

      Array.prototype.forEach.call(
        document.querySelectorAll('.inputMod'),
        (element) => {
          element.addEventListener('blur', () => {
            setActive(element, false);
          });

          element.addEventListener('focus', () => {
            setActive(element, true);
          });
        }
      );
    }
    const classNames = [];
    if (this.state.isValidated) {
      classNames.push('was-validated');
    }
    return (
      <Layout>
        <Header />
        <div className="wrapper-request-form">
          <div className="container">
            <form
              method="post"
              action="#"
              ref={(form) => {
                this.formEl = form;
              }}
              className={classNames}
              noValidate
            >
              {this.state.mailSent && (
                <div className="alert-success alert text-center mb-5">
                  Thank you for contacting us.
                </div>
              )}
              {this.state.error && (
                <div className="alert-danger error alert text-center  mb-5">
                  {this.state.error}
                </div>
              )}
              <div className="body-req">
                <Swiper
                  modules={[
                    Navigation,
                    EffectFade,
                    Mousewheel,
                    Pagination,
                    Scrollbar,
                  ]}
                  effect="fade"
                  direction={'vertical'}
                  mousewheel={true}
                  navigation={{
                    nextEl: '.next-nav',
                    prevEl: '.prev-nav',
                  }}
                  pagination={{
                    type: 'progressbar',
                    el: '.progress-bb',
                    verticalClass: 'swiper-pagination-horizontal',
                  }}
                  slidesPerView={1}
                  watchSlidesProgress
                  scrollbar={{
                    hide: false,
                    draggable: true,
                  }}
                  allowTouchMove={false}
                  onSlideChange={(e) => {
                    this.setState({ slideCount: e.realIndex });
                  }}
                  className={'item-hide-' + this.state.slideCount}
                >
                  <SwiperSlide>
                    <div className="scrollSp">
                      <div className="item-req">
                        <label htmlFor="email">Email *</label>
                        <div className="label-description">
                          Let's start with your email address:
                        </div>
                        <div className="input-hold">
                          <input
                            name="email"
                            type="email"
                            className="form-control"
                            value={this.state.email}
                            onChange={this.handleChangeEmail}
                            required
                          />
                          <div className="invalid-feedback" />
                        </div>
                      </div>
                      <div className="item-req">
                        <label htmlFor="email">Name *</label>
                        <div className="label-description">
                          Ok, now what about your name?
                        </div>
                        <div className="input-hold">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="">First Name*</label>
                                <input
                                  type="text"
                                  name="first"
                                  className="form-control"
                                  value={this.state.first}
                                  onChange={this.handleChangeText}
                                  required
                                />
                                <div className="invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="">Last Name*</label>
                                <input
                                  type="text"
                                  name="last"
                                  className="form-control"
                                  value={this.state.last}
                                  onChange={this.handleChangeLastName}
                                  required
                                />
                                <div className="invalid-feedback" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="scrollSp">
                      <div className="item-req">
                        <label htmlFor="email">Phone</label>
                        <div className="label-description">
                          What's a good phone number to reach you at?
                        </div>
                        <div className="input-hold">
                          <InputMask
                            mask=" 999 999-9999"
                            maskChar=" "
                            value={this.state.phone}
                            onChange={(e) => {
                              this.setState({ phone: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="scrollSp">
                      <div className="item-req">
                        <label htmlFor="email">
                          Have you worked with a financial adviser before?
                        </label>
                        <div className="input-hold">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="finadv"
                                  id="chck"
                                  value="Yes"
                                  onChange={(e) => {
                                    this.setState({
                                      financialAdviser: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="chck">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> A
                                    </small>{' '}
                                    Yes
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="finadv"
                                  id="chck-b"
                                  value="No"
                                  onChange={(e) => {
                                    this.setState({
                                      financialAdviser: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="chck-b">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> b
                                    </small>{' '}
                                    No
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="scrollSp">
                      <div className="item-req">
                        <label htmlFor="email">
                          How familiar are you with our investment philosophy?
                        </label>
                        <div className="input-hold">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="invphi"
                                  id="ll1"
                                  value="Little familiarity"
                                  onChange={(e) => {
                                    this.setState({
                                      investmentPhilosophy: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="ll1">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> A
                                    </small>{' '}
                                    Little familiarity
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="invphi"
                                  id="ll2"
                                  value="I get the basics"
                                  onChange={(e) => {
                                    this.setState({
                                      investmentPhilosophy: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="ll2">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> b
                                    </small>{' '}
                                    I get the basics
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="invphi"
                                  id="ll3"
                                  value="I understand the philosophy"
                                  onChange={(e) => {
                                    this.setState({
                                      investmentPhilosophy: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="ll3">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> b
                                    </small>{' '}
                                    I understand the philosophy
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="scrollSp">
                      <div className="item-req">
                        <label htmlFor="email">
                          What kind of investor are you?
                        </label>
                        <div className="input-hold">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="invtyp"
                                  id="i1"
                                  value="Individual"
                                  onChange={(e) => {
                                    this.setState({
                                      investorType: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="i1">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> A
                                    </small>{' '}
                                    Individual
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="invtyp"
                                  id="i2"
                                  value="Institution"
                                  onChange={(e) => {
                                    this.setState({
                                      investorType: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="i2">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> b
                                    </small>{' '}
                                    Institution
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="invtyp"
                                  id="i3"
                                  value="Corporate Retirement Plan"
                                  onChange={(e) => {
                                    this.setState({
                                      investorType: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="i3">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> C
                                    </small>{' '}
                                    Corporate Retirement Plan
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="scrollSp">
                      <div className="item-req">
                        <label htmlFor="email">
                          What is your investing experience?
                        </label>
                        <div className="input-hold">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="invexp"
                                  id="j1"
                                  value="Novice"
                                  onChange={(e) => {
                                    this.setState({
                                      investingExperience: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="j1">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> A
                                    </small>{' '}
                                    Novice
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="invexp"
                                  id="j2"
                                  value="Intermediate"
                                  onChange={(e) => {
                                    this.setState({
                                      investingExperience: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="j2">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> b
                                    </small>{' '}
                                    Intermediate
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="invexp"
                                  id="j3"
                                  value="Experienced"
                                  onChange={(e) => {
                                    this.setState({
                                      investingExperience: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="j3">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> C
                                    </small>{' '}
                                    Experienced
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="scrollSp">
                      <div className="item-req">
                        <label htmlFor="email">Investable Assets</label>
                        <div className="label-description">
                          Tell us a little about your assets so we can best
                          assign services to your unique needs.
                        </div>
                        <div className="input-hold">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="invassets"
                                  id="k1"
                                  value="Less than $250k"
                                  onChange={(e) => {
                                    this.setState({
                                      investableAssets: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="k1">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> A
                                    </small>{' '}
                                    Less than $250k
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="invassets"
                                  id="k2"
                                  value="Between $250k - $1mil"
                                  onChange={(e) => {
                                    this.setState({
                                      investableAssets: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="k2">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> b
                                    </small>{' '}
                                    Between $250k - $1mil
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="invassets"
                                  id="k3"
                                  value="Between $1mil - $3mil"
                                  onChange={(e) => {
                                    this.setState({
                                      investableAssets: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="k3">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> C
                                    </small>{' '}
                                    Between $1mil - $3mil
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="invassets"
                                  id="k4"
                                  value="Between $3mil - $7mil"
                                  onChange={(e) => {
                                    this.setState({
                                      investableAssets: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="k4">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> d
                                    </small>{' '}
                                    Between $3mil - $7mil
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="invassets"
                                  id="k5"
                                  value="Between $7mil - $25mil"
                                  onChange={(e) => {
                                    this.setState({
                                      investableAssets: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="k5">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> e
                                    </small>{' '}
                                    Between $7mil - $25mil
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="invassets"
                                  id="k6"
                                  value="Greater than $25mil"
                                  onChange={(e) => {
                                    this.setState({
                                      investableAssets: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="k6">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> f
                                    </small>{' '}
                                    Greater than $25mil
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="scrollSp">
                      <div className="item-req">
                        <label htmlFor="email">
                          Finally, how'd you hear about us?
                        </label>
                        <div className="input-hold">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="hear"
                                  id="l1"
                                  value="Josh Brown on CNBC"
                                  onChange={(e) => {
                                    this.setState({
                                      hearAboutUs: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="l1">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> A
                                    </small>{' '}
                                    Josh Brown on CNBC
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="hear"
                                  id="l2"
                                  value="Referral from Existing RWM Client"
                                  onChange={(e) => {
                                    this.setState({
                                      hearAboutUs: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="l2">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> b
                                    </small>{' '}
                                    Referral from Existing RWM Client
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="hear"
                                  id="l3"
                                  value="Barry Ritholtz on Bloomberg"
                                  onChange={(e) => {
                                    this.setState({
                                      hearAboutUs: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="l3">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> C
                                    </small>{' '}
                                    Barry Ritholtz on Bloomberg
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="hear"
                                  id="l4"
                                  value="Barry Ritholtz on The Big Picture"
                                  onChange={(e) => {
                                    this.setState({
                                      hearAboutUs: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="l4">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> d
                                    </small>{' '}
                                    Barry Ritholtz on The Big Picture
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="hear"
                                  id="l5"
                                  value="Josh Brown on The Reformed Broker"
                                  onChange={(e) => {
                                    this.setState({
                                      hearAboutUs: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="l5">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> e
                                    </small>{' '}
                                    Josh Brown on The Reformed Broker
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="hear"
                                  id="l6"
                                  value="YouTube (The Compound)"
                                  onChange={(e) => {
                                    this.setState({
                                      hearAboutUs: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="l6">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> f
                                    </small>{' '}
                                    YouTube (The Compound)
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="hear"
                                  id="l7"
                                  value="Animal Spirits with Mike and Ben"
                                  onChange={(e) => {
                                    this.setState({
                                      hearAboutUs: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="l7">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> g
                                    </small>{' '}
                                    Animal Spirits with Mike and Ben
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="hear"
                                  id="l8"
                                  value="Ben Carlson on A Wealth of Common Sense"
                                  onChange={(e) => {
                                    this.setState({
                                      hearAboutUs: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="l8">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> h
                                    </small>{' '}
                                    Ben Carlson on A Wealth of Common Sense
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="hear"
                                  id="l9"
                                  value="Ben Carlson on Fortune Magazine"
                                  onChange={(e) => {
                                    this.setState({
                                      hearAboutUs: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="l9">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> i
                                    </small>{' '}
                                    Ben Carlson on Fortune Magazine
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="hear"
                                  id="l10"
                                  value="Michael Batnick on The Irrelevant Investor"
                                  onChange={(e) => {
                                    this.setState({
                                      hearAboutUs: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="l10">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> j
                                    </small>{' '}
                                    Michael Batnick on The Irrelevant Investor
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="hear"
                                  id="l11"
                                  value="Blair duQuesnay on The Belle Curve"
                                  onChange={(e) => {
                                    this.setState({
                                      hearAboutUs: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="l11">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> k
                                    </small>{' '}
                                    Blair duQuesnay on The Belle Curve
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="hear"
                                  id="l12"
                                  value="Nick Maggiulli on Of Dollars and Data"
                                  onChange={(e) => {
                                    this.setState({
                                      hearAboutUs: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="l12">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> l
                                    </small>{' '}
                                    Nick Maggiulli on Of Dollars and Data
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="scrollSp">
                      <div className="item-req">
                        <label htmlFor="email">
                          How Often Do you Read Our Blogs?
                        </label>
                        <div className="label-description">
                          Our sites include: The Big Picture, A Wealth of Common
                          Sense, The Reformed Broker, The Irrelevant Investor,
                          Abnormal Returns, Of Dollars and Data, A Teachable
                          Moment, and The Belle Curve.
                        </div>
                        <div className="input-hold">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="readblog"
                                  id="m1"
                                  value="Nearly everyday"
                                  onChange={(e) => {
                                    this.setState({
                                      readBlogs: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="m1">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> A
                                    </small>{' '}
                                    Nearly everyday
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="readblog"
                                  id="m2"
                                  value="A few times per week"
                                  onChange={(e) => {
                                    this.setState({
                                      readBlogs: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="m2">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> b
                                    </small>{' '}
                                    A few times per week
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="readblog"
                                  id="m3"
                                  value="2 - 3 times per month"
                                  onChange={(e) => {
                                    this.setState({
                                      readBlogs: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="m3">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> C
                                    </small>{' '}
                                    2 - 3 times per month
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="readblog"
                                  id="m4"
                                  value="Once a month, or less"
                                  onChange={(e) => {
                                    this.setState({
                                      readBlogs: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="m4">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> d
                                    </small>{' '}
                                    Once a month, or less
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="scrollSp">
                      <div className="item-req">
                        <label htmlFor="email">Contact Method</label>
                        <div className="label-description">
                          How would you like us to contact you?
                        </div>
                        <div className="input-hold">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="ctm"
                                  id="n1"
                                  value="Email"
                                  onChange={(e) => {
                                    this.setState({
                                      contactMethod: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="n1">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> A
                                    </small>{' '}
                                    Email
                                  </div>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="checkbox">
                                <input
                                  type="radio"
                                  name="ctm"
                                  id="n2"
                                  value="Phone"
                                  onChange={(e) => {
                                    this.setState({
                                      contactMethod: e.target.value,
                                    });
                                  }}
                                />
                                <label htmlFor="n2">
                                  {' '}
                                  <div>
                                    <small>
                                      <span>key</span> b
                                    </small>{' '}
                                    Phone
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="scrollSp">
                      <div className="item-req">
                        <label htmlFor="email">Why did you reach out?</label>
                        <div className="label-description">
                          What was your motivation for reaching out to us?
                        </div>
                        <div className="input-hold">
                          <textarea
                            className="form-control"
                            value={this.state.message}
                            onChange={(e) => {
                              this.setState({ message: e.target.value });
                            }}
                          />
                        </div>
                        <br />
                        <br />
                        <hr />
                        <br />
                        <br />
                        <div className="input-hold text-center">
                          <button
                            className="btn-theme btn-sm"
                            type="submit"
                            onClick={(e) => this.handleRequestFormSubmit(e)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div className="btn-wrap wrap-rfq-sticky">
                  {!this.state.submitDisabled && (
                    <button
                      type="submit"
                      className="btn-theme btn-sm submit"
                      onClick={(e) => this.handleRequestFormSubmit(e)}
                    >
                      Submit
                    </button>
                  )}
                  &nbsp;
                  <button type="button" className="btn-theme btn-sm next-nav">
                    Continue
                  </button>
                  <div className="label-description btn-help-text">
                    <b>
                      In a hurry? Submit this form with just your name and email
                      and we'll get in touch
                    </b>
                  </div>
                </div>
                <div className="bottom-controls">
                  <div className="progress-ab">
                    <div className="progress-bb"></div>
                  </div>
                  <div className="nav">
                    <button className="prev-nav"> </button>
                    <button className="next-nav"> </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Index;
